const reduceFormulation = (formulation, levelIndex) => {
  const previewLevels = formulation.slice(0, levelIndex);

  if (previewLevels.length === 0) {
    return formulation[0].tasks.map(task => ({
      id: task.id,
      name: `${formulation[0].name} ${task.nestedPositions}`,
      parentId: task.parentId,
      rootParentId: task.rootParentId || task.parentId,
      nestedPositions: task.nestedPositions
    }));
  }

  return previewLevels.reduce((accum, current, index) => {
    if (index === 0) {
      return accum.tasks.map(x => ({ name: `${accum.name} ${x.nestedPositions}`, ...x }));
    }

    const mappingTasks = ({ id, parentId, nestedPositions, rootParentId }, setParentId) => {
      const parentPatch = accum.find(parent => parent.id === parentId).name || '';

      return {
        id,
        name: `${parentPatch} > ${current.name} ${nestedPositions}`,
        rootParentId: setParentId ? parentId : rootParentId,
        parentId,
        nestedPositions
      };
    };

    if (index === 1) {
      return current.tasks.map(task => mappingTasks(task, true));
    }

    return current.tasks.map(mappingTasks);
  }, previewLevels[0]);
};

export const groupTaskByLvl = formulation => {
  if (formulation.length <= 0) return [];

  const previewLevels = formulation.slice(0, formulation.length);
  const lastLevel = previewLevels.pop();
  const reducedPreviewLevels = reduceFormulation(formulation, formulation.length - 1);

  const { tasks: tasksLastLevel, name: levelName } = lastLevel;

  const penultimateLevelReduced = reducedPreviewLevels
    .filter(x => tasksLastLevel.find(y => y.parentId === x.id))
    .map(x => ({
      children: tasksLastLevel.filter(y => y.parentId === x.id).map(z => ({ levelName, ...z })),
      ...x
    }));

  const grouped = [];

  penultimateLevelReduced.forEach(level => {
    if (grouped.length === 0) {
      grouped.push({
        id: level.rootParentId,
        name: level.name.replace(/ > .*/, ''),
        children: [
          {
            id: level.id,
            name: level.name,
            children: level.children.map(({ description, id, levelName: name, nestedPositions }) => ({
              name: `${name} ${nestedPositions}`,
              id,
              description
            }))
          }
        ]
      });
    } else {
      const levelFound = grouped.find(x => x.id === level.rootParentId);

      if (levelFound) {
        levelFound.children.push({
          id: level.id,
          name: level.name,
          children: level.children.map(({ description, id, levelName: name, nestedPositions }) => ({
            name: `${name} ${nestedPositions}`,
            id,
            description
          }))
        });
      } else {
        grouped.push({
          id: level.rootParentId,
          name: level.name.replace(/ > .*/, ''),
          children: [
            {
              id: level.id,
              name: level.name,
              children: level.children.map(({ description, id, levelName: name, nestedPositions }) => ({
                name: `${name} ${nestedPositions}`,
                id,
                description
              }))
            }
          ]
        });
      }
    }
  });

  return grouped;
};

export const groupByItems = (budgetLines = []) => {
  const grouped = [];

  budgetLines.forEach(budgetLine => {
    if (grouped.length === 0) {
      grouped.push({
        id: budgetLine.budgetCategory.id,
        name: budgetLine.budgetCategory.name,
        children: [
          {
            id: budgetLine.subBudgetCategory.id,
            name: budgetLine.subBudgetCategory.name,
            children: [budgetLine]
          }
        ]
      });
      return;
    }

    const categoryId = budgetLine.budgetCategory.id;

    const categoryFound = grouped.find(category => category.id === categoryId);

    if (categoryFound) {
      const subBudgetCategoryFound = categoryFound.children.find(
        subBudgetCategory => subBudgetCategory.id === budgetLine.subBudgetCategory.id
      );

      if (!subBudgetCategoryFound) {
        categoryFound.children.push({
          id: budgetLine.subBudgetCategory.id,
          name: budgetLine.subBudgetCategory.name,
          description: '',
          children: [budgetLine]
        });
      } else {
        subBudgetCategoryFound.children.push(budgetLine);
      }
    } else {
      grouped.push({
        id: budgetLine.budgetCategory.id,
        name: budgetLine.budgetCategory.name,
        description: '',
        children: [
          {
            id: budgetLine.subBudgetCategory.id,
            name: budgetLine.subBudgetCategory.name,
            description: '',
            children: [budgetLine]
          }
        ]
      });
    }
  });
  return [{ name: '', children: grouped }];
};

export const filterOptions = (filtered, state, label = '') => {
  const { isExactMatch, criteria } = state;

  if (isExactMatch) {
    filtered.push({
      newValue: criteria,
      name: `${label} ${criteria}`
    });
  }
  return filtered;
};
